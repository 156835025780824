<!-- Page Header Start-->
<div class="page-header" [class.close_icon]="navServices.collapseSidebar">
  <div class="header-wrapper row m-0">
    <app-search></app-search>
    <div class="col-2 header-logo-wrapper">
      <div class="logo-wrapper">
        <a routerLink="/">
          <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="" />
          <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="" />
        </a>
      </div>
      <div class="toggle-sidebar" (click)="sidebarToggle()">
        <app-feather-icons [icon]="'align-center'" class="status_toggle middle"></app-feather-icons>
      </div>
    </div>
    <div class="nav-right col-10 pull-right right-header p-0">
      <ul class="nav-menus mr-0">

        <ng-container *ngIf="isBetaTesterOrDesktop()">
          <li style="margin-right: 100px;"> <button class="btn btn-primary" routerLink="/buy">
              KUPI</button></li>
        </ng-container>
        <app-context *ngIf="isDesktop"></app-context>
        <li>
          <span class="header-search" (click)="searchToggle()">
            <i class="fa fa-search fa-lg"></i>
          </span>
        </li>
        <li class="onhover-dropdown">
          <app-notification></app-notification>
        </li>
        <li>
          <div class="mode" (click)="layoutToggle()">
            <i class="fa fa-moon-o mt-1" *ngIf="!dark"></i>
            <i class="fa fa-lightbulb-o mt-1" *ngIf="dark"></i>
          </div>
        </li>

        <li class="cart-nav onhover-dropdown">
          <div class="cart-box">
            <i class="fa fa-question-circle fa-lg"></i>
          </div>
          <div class="cart-dropdown onhover-show-div active">
            <!-- <h6 class="f-18 mb-0 dropdown-title">{{"PROPERTIES.Pomoć" | translate}}</h6> -->
            <ul>
              <a class="dropdown-item pl-2 py-2" (click)="onBoardArticle()">
                <i class="fa fa-question fa-fw mr-1"></i>
                {{"PROPERTIES.Pomoć pri izradi artikla" | translate}}
              </a>
              <a class="dropdown-item pl-2 py-2" (click)="onBoardFaktura()">
                <i class="fa fa-question fa-fw mr-1"></i>
                {{"PROPERTIES.Pomoć pri izradi fakture" | translate}}
              </a>

              <a class="dropdown-item pl-2 py-2" (click)="onBoardKalkulacija()">
                <i class="fa fa-question fa-fw mr-1"></i>
                {{"PROPERTIES.Pomoć pri izradi kalkulacije" | translate}}
              </a>

              <a class="dropdown-item pl-2 py-2" (click)="onBoardPostavke()">
                <i class="fa fa-question fa-fw mr-1"></i>
                {{"PROPERTIES.Pomoć pri postavljanju memoranduma" | translate}}
              </a>

              <a class="dropdown-item pl-2 py-2" (click)="onBoardPotpis()">
                <i class="fa fa-question fa-fw mr-1"></i>
                {{"PROPERTIES.Pomoć pri postavljanju potpisa" | translate}}
              </a>

              <a class="dropdown-item pl-2 py-2" (click)="onBoardPecat()">
                <i class="fa fa-question fa-fw mr-1"></i>
                {{"PROPERTIES.Pomoć pri postavljanju pečata" | translate}}
              </a>

              <a class="dropdown-item pl-2 py-2" (click)="onBoardKUF()">
                <i class="fa fa-question fa-fw mr-1"></i>
                {{"PROPERTIES.Pomoć pri unosu KUF-a" | translate}}
              </a>

              <a class="dropdown-item pl-2 py-2" (click)="onBoardFinNalog()">
                <i class="fa fa-question fa-fw mr-1"></i>
                {{"PROPERTIES.Pomoć pri unosu naloga" | translate}}
              </a>

              <a class="dropdown-item pl-2 py-2" (click)="linkZaPomoc()">
                <i class="fa fa-question fa-fw mr-1"></i>
                {{"PROPERTIES.Pomoć pri korištenju programa fi-we" | translate}}
              </a>
            </ul>
          </div>
        </li>

        <li class="language-nav" (click)="languageToggle()">
          <app-languages></app-languages>
        </li>


        <li class="onhover-dropdown">
          <app-user-dropdown></app-user-dropdown>
        </li>

        @if (!isDesktop) {
        <li class="onhover-dropdown">
          <div>
            <i class="fa fa-building"></i>
          </div>
          <div class="onhover-show-div active context-size">
            <app-context></app-context>
          </div>
        </li>
        <li>
          <h6 class="mt-2"> {{ (accountService?.contextFirmData?.nFirma.length > 25)?
            (accountService?.contextFirmData?.nFirma | slice:0:25)+'...':(accountService?.contextFirmData?.nFirma) }}
          </h6>
        </li>
        }
        <!--  <li class="maximize">
          <a class="text-dark" href="javascript:void(0)" (click)="toggleFullScreen()">
            <app-feather-icons [icon]="'maximize'"></app-feather-icons>
          </a>
        </li> -->

      </ul>
    </div>
  </div>
</div>
<!-- Page Header Ends -->