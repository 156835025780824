import { Component } from '@angular/core';

@Component({
  selector: 'app-uvod-u-koristenje-programa-fiwe',
  templateUrl: './uvod-u-koristenje-programa-fiwe.component.html',
  styleUrl: './uvod-u-koristenje-programa-fiwe.component.scss'
})
export class UvodUKoristenjeProgramaFiweComponent {

}
