import { AccountService } from 'src/app/shared/services/account.service';
import { FirmsService } from './../../services/firms.service';
import { Component, EventEmitter, Inject, OnInit, inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';
import { APP_LAYOUT } from '../../../_constants/appMessages';
import { BetaKorisnik, Tester, } from 'src/app/_constants/ApplicationRoles';
import { ConfigurationsService } from 'src/app/_services/services/configurations.service';
import { IStepOption, TourService } from 'ngx-ui-tour-ngx-bootstrap';
import { AnchorStepsConstants } from 'src/app/_constants/anchorStepsConstants';
import { TranslateService } from '@ngx-translate/core';
import { NgxTourService } from '../../services/ngx-tour.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public openMessageBox: boolean = false;
  public elem: any;
  public dark: boolean =
    this.layout.config.settings.layout_version == 'dark-only' ? true : false;
  isDesktop: boolean = window.innerWidth > 990; // && window.outerWidth > 990;
  accountInfo: any = localStorage.getItem('accountInfo');
  dashboardPhotoUrl;
  public isMobile: boolean = window.innerWidth < 991 && window.outerWidth < 991;
  static SIDEBAR_OPENED = new EventEmitter();

  private readonly stepsMemorandum: IStepOption[] = this.stepsAnchor.stepsMemorandum();
  private readonly stepsPotpis: IStepOption[] = this.stepsAnchor.stepsPotpis();
  private readonly stepePecat: IStepOption[] = this.stepsAnchor.stepsPecat();
  private readonly stepsArtikli: IStepOption[] = this.stepsAnchor.stepsArtikli();
  private readonly stepsFaktura: IStepOption[] = this.stepsAnchor.stepsFaktura();
  private readonly stepsKalkulacija: IStepOption[] = this.stepsAnchor.stepsKalkulacija();
  private readonly stepsKUF: IStepOption[] = this.stepsAnchor.stepsKUF();
  private readonly stepsFinNalog: IStepOption[] = this.stepsAnchor.stepsUnosNaloga();

  private readonly tourService = inject(TourService);
  isSettingDropDownOppened = false;

  constructor(
    public layout: LayoutService,
    public navServices: NavService,
    public firmsService: FirmsService,
    public accountService: AccountService,
    private stepsAnchor: AnchorStepsConstants,
    private translate: TranslateService,
    private ngxtourService: NgxTourService,
    @Inject(DOCUMENT) private document: any
  ) { }

  get firstName() {
    return JSON.parse(this.accountInfo)?.firstName;
  }
  get lastName() {
    return JSON.parse(this.accountInfo)?.lastName;
  }

  ngOnInit() {
    this.elem = document.documentElement;
    this.getInitialLayout();
    //console.log(window.innerWidth,  window.outerWidth)

    this.tourService.end$.subscribe(resp => {
      this.isSettingDropDownOppened = false;
    })

  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    this.navServices.megaMenu = false;
    this.navServices.levelMenu = false;
    this.hideSearchDropdown();
  }

  hideSearchDropdown() {
    HeaderComponent.SIDEBAR_OPENED.emit(true);
  }

  isBetaTesterOrDesktop() {
    const isSuperAdmin = this.accountService.currentUser.userRoles.find(
      (r) => r.roleName === Tester || r.roleName === BetaKorisnik
    );
    if (!this.isDesktop) return false;
    if (isSuperAdmin) return true;
    else return false;
  }

  layoutToggle() {
    this.dark = !this.dark;
    this.layout.config.settings.layout_version = this.dark
      ? 'dark-only'
      : 'light';
    localStorage.setItem(
      APP_LAYOUT,
      this.layout.config.settings.layout_version
    );
  }

  searchToggle() {
    this.navServices.search = true;
  }

  languageToggle() {
    this.navServices.language = !this.navServices.language;
  }

  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    this.navServices.collapseSidebar = true;
    if (this.navServices.fullScreen) {
      this.enterFullscreen();
    } else {
      this.exitFullscreen();
    }
  }

  enterFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }

  exitFullscreen() {
    if (!this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }

  private getInitialLayout(): void {
    const layout = localStorage.getItem(APP_LAYOUT);
    if (layout) {
      this.dark = layout === 'dark-only';
      this.layout.config.settings.layout_version = layout;
    }
  }

  onBoardArticle() {
    this.ngxtourService.startTourServiceArtikli(this.stepsArtikli);
  }

  onBoardFaktura() {
    this.ngxtourService.startTourServiceFakture(this.stepsFaktura);
  }

  onBoardKalkulacija() {
    this.ngxtourService.startTourServiceKalkulacija(this.stepsKalkulacija);
  }

  onBoardPostavke() {
    this.openSettingDrowdown();
    this.ngxtourService.startTourService(this.stepsMemorandum);
  }

  onBoardPotpis() {
    this.openSettingDrowdown();
    this.ngxtourService.startTourService(this.stepsPotpis);
  }

  onBoardPecat() {
    this.openSettingDrowdown();
    this.ngxtourService.startTourService(this.stepePecat);
  }

  openSettingDrowdown() {
    this.isSettingDropDownOppened = true;
  }

  onBoardKUF() {
    this.ngxtourService.startTourServiceKUF(this.stepsKUF);
  }

  onBoardFinNalog() {
    this.ngxtourService.startTourServiceUnosNaloga(this.stepsFinNalog);
  }

  linkZaPomoc(){
    var url = `https://www.loom.com/embed/01e7fadd35774f23bca06e5cf6b878c0?sid=f6c570f2-5c36-4591-a88c-6713667d69b4`;
    window.open(url, "_blank");
  }
}
